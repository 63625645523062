import { saveAs } from 'file-saver'

export default function (data, fileName) {
  var fileToSave = new Blob([JSON.stringify(data)], {
    type: 'application/json',
    name: data
  })

  saveAs(fileToSave, fileName)
}
