<template>
  <div>
    <h1 class="text-2xl">
      Medialibrary
    </h1>
    <span>{{ items.length }} images on device</span>
    <div class="flex flex-wrap">
      <div v-for="item in currentPageItems" :key="item" class="w-1/4 p-3">
        <media-library-image :id="item" @selected="selectImage" />
      </div>
    </div>
    <div v-if="!loading && items.length" class="flex flex-col items-center mt-5">
      <ul class="flex items-stretch justify-center mb-2">
        <li class="mr-2" @click="prevPage">
          <BaseButton class="h-full" :color="hasPreviousPage ? 'green' : 'gray'">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </BaseButton>
        </li>
        <li v-for="number in pageNumbers" :key="number" class="px-2">
          <button class="px-8 py-2 border border-gray-500 rounded" :class="{ 'bg-blue-400 text-white': number === currentPage }" @click="currentPage = number">
            {{ number }}
          </button>
        </li>
        <li class="ml-2" @click="nextPage">
          <BaseButton class="h-full" :color="hasNextPage ? 'green' : 'gray'">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </BaseButton>
        </li>
      </ul>
      <span>{{ currentPage }} of {{ totalPages }} </span>
    </div>
    <div v-else-if="!loading && !items.length" class="flex items-center justify-center h-400">
      No images on device
    </div>
    <div v-else class="flex items-center justify-center h-400">
      <AppLoader />
    </div>
  </div>
</template>

<script>
import imageModal from '@/database/models/image.js'
import MediaLibraryImage from '~/components/MediaLibraryImage'
import BaseButton from '~/components/base/BaseButton'
import AppLoader from '~/components/AppLoader'

export default {
  components: { AppLoader, BaseButton, MediaLibraryImage },
  props: {
    perPage: {
      type: Number,
      default: 8
    },
    padding: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      items: [],
      currentPage: 1,
      loading: true
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.items.length / this.perPage)
    },
    currentPageItems () {
      return this.items.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
    },
    hasNextPage () {
      return this.currentPage !== this.totalPages
    },
    hasPreviousPage () {
      return this.currentPage > 1
    },
    totalItemsToShow () {
      return 1 + (this.padding * 2)
    },
    pageNumbers () {
      const items = Array.from({ length: this.totalPages }, (_, i) => i + 1)

      // Get first 5 items
      if (this.currentPage <= this.padding) {
        return items.slice(0, this.totalItemsToShow)
      }

      // Get last 5 items
      if (this.currentPage >= (this.totalPages - this.padding)) {
        return items.slice(-this.totalItemsToShow)
      }

      return items.slice((this.currentPage - 1) - this.padding, this.currentPage + this.padding)
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    async getItems () {
      this.items = await imageModal.keys()
      this.items = this.items.sort((a, b) => {
        return parseInt(b) - parseInt(a)
      })
      this.loading = false
    },
    prevPage () {
      this.hasPreviousPage && this.currentPage--
    },
    nextPage () {
      this.hasNextPage && this.currentPage++
    },
    selectImage (image) {
      this.$emit('selected', image)
    }
  }
}
</script>
