export default [
  {
    id: 'Damage',
    color: 'bg-orange-600',
    name: 'Damage'
  },
  {
    id: 'Repair',
    color: 'bg-blue-600',
    name: 'Repair'
  },
  {
    id: 'No label',
    color: false,
    name: 'No label'
  }
]
