<template>
  <div class="w-full">
    <BaseModel :open="image.isOpen" @change="changeOpenState">
      <template #default="{ openModel }">
        <div v-if="image.filename" class="relative flex items-center">
          <div :class="{ 'border border-gray-400 rounded bg-gray-200': !image.filename }" class="relative flex items-center justify-center w-full overflow-hidden h-400" @click="openModel">
            <BaseImageViewer :value="image.filename" class="absolute-center"></BaseImageViewer>
          </div>
          <div v-if="image.filename" class="absolute top-0 bottom-0 left-0 right-0 gradient hover:cursor-pointer" @click="openModel"></div>
          <span class="absolute text-3xl text-white bottom-15 left-15">
            {{ image.description }}
          </span>
          <span v-if="imageType && imageType.color" :class="imageType.color" class="absolute top-0 left-0 flex justify-center w-1/4 py-4 text-white">
            {{ imageType.name }}
          </span>
          <BaseModel v-model="removeModel">
            <template #default="{ openModel }">
              <span class="absolute top-0 right-0 flex justify-center w-1/2 py-4 text-white bg-red-700" @click="openModel">
                Remove
              </span>
            </template>

            <template #content>
              <h2>Are you sure you want to remove this image?</h2>
              <div class="flex mt-10">
                <BaseButton color="gray" class="mr-5" @click="removeModel = false">
                  Cancel
                </BaseButton>
                <BaseButton color="red" @click="removeImage(image)">
                  Remove
                </BaseButton>
              </div>
            </template>
          </BaseModel>
        </div>
      </template>

      <template #content>
        <h3 class="mb-6 text-4xl font-medium">
          Add photo
        </h3>
        <div class="flex">
          <div class="w-full mr-5">
            <BaseImage :value="image.filename" name="image.filename" :rules="rules('filename')" @input="emitChanges"></BaseImage>

            <BaseInput v-model="image.description" name="image.description" :showSymbols="true" :rules="rules('description')" label="Description" class="block mt-8"></BaseInput>
            <div class="flex items-center justify-between mt-10">
              <p class="text-red-600 text-bold">
                Warning! Make sure to fill in expire date & batch nr.
              </p>
              <div>
                <BaseButton color="gray" class="mr-5" @click="closeModel">
                  Close
                </BaseButton>
                <BaseButton color="green" @click="addImage">
                  Save
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseModel>
  </div>
</template>

<script>
import { sync, get } from 'vuex-pathify'

//Helpers
import imageTypes from '@/config/options/imagetype.js'
import rules from '@/helpers/rules.js'

export default {
  model: {
    prop: 'image'
  },
  props: {
    image: {
      type: Object,
      default: () => ({})
    },
    materialId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      removeModel: false
    }
  },
  computed: {
    report: sync('report'),
    imageTypes: () => imageTypes,
    imageType () {
      return imageTypes.find((type) => type.id === this.image.type) || {}
    }
  },
  methods: {
    rules: rules('materialImage'),
    cancelImage () {
      if (!this.image.filename) {
        this.removeImage()
      }
    },
    changeOpenState (isOpen) {
      if (!this.image.filename) {
        this.removeImage()
      } else {
        this.$emit('input', {
          ...this.image,
          isOpen
        })
      }
    },
    addImage () {
      if (!this.image.filename) {
        this.removeImage()
      }
      this.closeModel()
    },
    removeImage () {
      this.$emit('removeImage', this.image)
    },
    closeModel () {
      if (!this.image.filename) {
        this.removeImage()
      } else {
        this.$emit('input', {
          ...this.image,
          isOpen: false
        })
      }
    },
    emitChanges (filename) {
      this.$emit('input', {
        ...this.image,
        filename
      })
    }
  }
}
</script>

<style>
.gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.6+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); /* IE6-9 */
}
.cover-image {
  object-fit: cover;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
</style>
