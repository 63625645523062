<template>
  <div class="h-screen w-full overflow-hidden">
    <TheHeader></TheHeader>
    <nuxt class="h-screen-min-header" />
  </div>
</template>

<script>
export default {
  head: {
    bodyAttrs: {
      class: 'disable-scroll-chaining'
    }
  }
}
</script>

<style>
.disable-scroll-chaining {
  overscroll-behavior: contain;
}

.toasted-container {
  z-index: 10;
}
</style>
