import Compressor from 'compressorjs'

export default (file, quality = 0.6) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      width: 1200,
      mimeType: 'image/jpeg',
      success (result) {
        resolve(result)
      },
      error (err) {
        reject(err)
      }
    })
  })
}
