import localforage from 'localforage'

const metadata = localforage.createInstance({
  name: 'metadata'
})

const store = (key, data) => {
  metadata.setItem(key, data)
  return data
}

const get = (key) => {
  return metadata.getItem(key)
}

const update = (key, data) => {
  return new Promise((resolve, reject) => {
    metadata.setItem(key, data)
    resolve(data)
  })

}

const remove = async (key) => {
  return new Promise((resolve, reject) => {
    metadata.removeItem(key, (err, value) => resolve(value))
  })
}

const length = () => {
  return new Promise((resolve, reject) => {
    metadata.length((err, val) => resolve(val))
  })
}

const clear = () => {
  return new Promise((resolve, reject) => {
    metadata.clear((err, val) => resolve(val))
  })
}

const keys = () => {
  return new Promise((resolve, reject) => {
    metadata.keys((err, val) => resolve(val))
  })
}

export default {
  get,
  store,
  update,
  remove,
  length,
  keys,
  clear
}
