export default {
  work_type_id: '',
  service_interval_id: '',
  user_id: '',
  wtg_id: '',
  report_date: 'required',
  report_image: 'required',
  pad_number: '',
  turbine_type: 'required|min:1|max:255',
  kwl: 'required|integer|min:0|max:999999999',
  kwh: 'required|integer|min:0|max:999999999',
  hub_height: 'required|min:1|max:255',
  address: 'required|min:1|max:255',
  client: 'required|min:1|max:255',
  nrc_number: 'required|min:1|max:255',
  contact: 'required|min:1|max:255',
  service_engineers: 'required|min:1|max:255',
  blades: [],
  materials: []
}
