<template>
  <baseList :items="bladesWithModel">
    <template #default="{ item }">
      <BladeListItem :item="item"></BladeListItem>
    </template>
  </baseList>
</template>

<script>
import { sync } from 'vuex-pathify'
import BladeListItem from '@/components/blades/BladeListItem.vue'

export default {
  components: {
    BladeListItem
  },
  computed: {
    blades: sync('report@blades'),
    blade: sync('blade'),
    bladesWithModel () {
      return this.blades.map(blade => ({ ...blade, open: false }))
    }
  },
  methods: {
    edit (blade) {
      this.blade = blade
    },
    remove (blade) {
      this.blades = this.blades.filter((_blade) => _blade.id !== blade.id)
    }
  }
}
</script>
