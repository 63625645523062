import reportModel from '@/database/models/report.js'
import imageModel from '@/database/models/image.js'

const imageKeys = [
  'report_image',
  'blade_image',
  'filename'
]

export default (data) => {
  return new Promise(async (resolve, reject) => {

    const imageKey = Math.floor(Date.now() / 1000).toString()
    let iterator = 0

    const newReport = await jsonValueReplacer(data, async (prop, val) => {

      if (val === null) {
        return val;
      }

      if (!imageKeys.includes(prop)) {
        return val;
      }

      const key = imageKey + iterator
      iterator++

      await imageModel.store(key, val.image, {
        date: val.date,
      })

      return key
    })

    const report = await reportModel.store(data.id, newReport)

    resolve(report)
  })
}

const jsonValueReplacer = async (obj, callback) => {
  const modifiedObj = {}
  for (const key in obj) {
    let val

    if (imageKeys.includes(key)) {
      val = await callback(key, obj[key])
      modifiedObj[key] = val

      continue
    }

    if (Array.isArray(obj[key])) {
      val = obj[key].map(async (item) => {
        return jsonValueReplacer(item, callback)
      })

      modifiedObj[key] = await Promise.all(val)
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {

      val = await jsonValueReplacer(obj[key], callback)
      modifiedObj[key] = val
    } else {
      val = await callback(key, obj[key])
      modifiedObj[key] = val
    }
  }
  return modifiedObj
}
