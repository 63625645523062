<template>
  <div class="fixed bottom-0 right-0 pr-10 pb-10">
    <template v-if="disablelink">
      <div class="shadow h-12 flex justify-center items-center rounded-full bg-green-600 text-white rounded px-5" v-on="$listeners">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <nuxt-link :to="to" class="shadow h-12 flex justify-center items-center rounded-full bg-green-600 text-white rounded px-5">
        <slot></slot>
      </nuxt-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '/'
    },
    disablelink: {
      type: Boolean,
      default: false
    }
  }
}
</script>
