<script functional>
export default {
  props: {
    href: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    link: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ''
    }
  },
  render (h) {
    let attrs = {
      href: this.href,
      target: this.target
    }

    if (!this.link) {
      attrs = {}
    }

    const colors = {
      gray: 'bg-gray-500',
      green: 'bg-green-500',
      orange: 'bg-orange-500',
      red: 'bg-red-500'
    }

    return h(this.link ? 'a' : 'button', {
      class: `${colors[this.color]} text-white py-2 px-8 rounded`,
      attrs,
      on: this.$listeners
    }, this.$slots.default)
  }
}
</script>
