export default [{
  title: 'Start',
  validated: true,
  component: 'start'
},
{
  title: 'General',
  validated: false,
  component: 'general'
},
{
  title: 'Windfarm',
  validated: false,
  component: 'windfarm'
},
{
  title: 'Inspection',
  validated: false,
  component: 'inspection',
  isInspection: true,
  disableValidation: true
},
{
  title: 'Materials',
  validated: false,
  component: 'materials',
  disableValidation: true
}
]
