<template>
    <div class="fixed bottom-0 right-0 p-4">
        <div class="flex gap-4">
            <SpecialSymbolButton @select="select" symbol="Ω"></SpecialSymbolButton>
            <SpecialSymbolButton @select="select" symbol="°C"></SpecialSymbolButton>
            <SpecialSymbolButton @select="select" symbol="µm"></SpecialSymbolButton>
            <SpecialSymbolButton @select="select" symbol="%"></SpecialSymbolButton>
            <SpecialSymbolButton @select="select" symbol="m"></SpecialSymbolButton>
            <SpecialSymbolButton @select="select" symbol="mm" :wider="true"></SpecialSymbolButton>
            <SpecialSymbolButton @select="select" symbol="gsm" :wider="true"></SpecialSymbolButton>
        </div>
    </div>
</template>
<script>

export default {
    methods: {
        select (symbol) {
            this.$emit('select', symbol)
        }
    }
}
</script>
