<template>
    <div @click="addSymbol" :class="{ 'w-60': wider === true }" class="ml-2 text-2xl font-bold text-center bg-white border rounded rounded-md shadow cursor-pointer leading-50 h-50 w-50 bg-gray-150 hover:bg-gray-150">
      {{ symbol }}
    </div>
</template>
<script>

export default {
  props: {
    symbol: '',
    wider: false
  },
  methods: {
    addSymbol(e) {
        this.$emit('select', this.symbol)
    }
  }
}
</script>
