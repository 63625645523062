import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
// Require in a base component context

const requireComponent = require.context('@/components', true, /\w+\.(vue|js)$/)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(camelCase(fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')))
  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig)
})
