<template>
  <div class="w-full p-10">
    <ul>
      <li v-for="(item, i) in menu.items" :key="i">
        <ValidationObserver v-show="menu.current.title === item.title" v-slot="{ valid, validate }" slim>
          <form autocomplete="on" @submit="(e) => e.preventDefault()">
            <ReportPage :item="item" :valid="valid"></ReportPage>
          </form>
        </ValidationObserver>
      </li>
    </ul>
  </div>
</template>

<script>
// Helpers
import { sync } from 'vuex-pathify'

export default {
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menu: sync('menu')
  },
  watch: {
    valid: {
      handler (value) {
        this.menu.current.validated = value
      }
    }
  }
}
</script>
