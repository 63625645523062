<template>
  <img class="max-w-full" :src="image">
</template>

<script>
import image from '@/database/models/image.js'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      image: ''
    }
  },
  watch: {
    value: {
      handler: 'renderImage',
      immediate: true
    }
  },
  methods: {
    async renderImage () {
      this.image = await image.get(this.value)
    }
  }
}
</script>
