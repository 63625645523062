<template>
  <div class="w-full flex justify-between">
    <span>
      {{ item.name }}
    </span>
    <div class="flex">
      <BaseButton class="mr-5" color="orange" @click="edit(item)">
        Edit
      </BaseButton>
      <BaseModel v-model="removeModel">
        <template #default>
          <BaseButton color="red" @click="removeModel = true">
            Delete
          </BaseButton>
        </template>

        <template #content>
          <h2>Are you sure you want to delete this {{ serviceInterval(interval) }}: {{ item.name }}?</h2>
          <div class="flex mt-10">
            <BaseButton color="gray" class="mr-5" @click="removeModel = false">
              Cancel
            </BaseButton>
            <BaseButton color="red" @click="remove(item)">
              Remove
            </BaseButton>
          </div>
        </template>
      </BaseModel>
    </div>
  </div>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import serviceInterval from '@/helpers/serviceInterval.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      removeModel: false
    }
  },
  computed: {
    interval: get('report@service_interval_id'),
    blades: sync('report@blades'),
    blade: sync('blade')
  },
  methods: {
    serviceInterval,
    edit (blade) {
      this.blade = blade
    },
    remove (blade) {
      this.blades = this.blades.filter((_blade) => _blade.id !== blade.id)
      this.removeModel = false
    }
  }
}
</script>
