<template>
  <ValidationProvider v-slot="{ invalid }" :rules="rules">
    <label class="flex items-center w-full">
      <span class="flex flex-shrink-0 w-9">
        {{ label }}
        <span v-if="isRequired" class="text-red-800">
          *
        </span>
      </span>
    </label>
        <textarea
            :class="{ 'border-red-400 border-2': invalid }"
            class="w-full px-4 py-3 bg-gray-200 border border-gray-400 rounded"
            v-bind="$attrs"
            :value="value"
            @input="update"
            @change="change"
        >
           {{ value }}
      </textarea>
  </ValidationProvider>
</template>

<script>
import { sync } from 'vuex-pathify'
import save from '@/helpers/save.js'

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    isRequired () {
      return this.rules.split('|').includes('required')
    },
    vuexReport: sync('report'),
    blade: sync('blade')
  },
  methods: {
    update (e) {
      this.$emit('input', e.target.value)
    },
    async change () {
      if (this.vuexReport) {
        await save(this.vuexReport, this.blade)
      }
    }
  }
}
</script>
