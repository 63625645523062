<template>
  <div class="flex">
    <ReportSidebar :saving="saving" @save="save"></ReportSidebar>
    <ValidationObserver
      v-slot="{ valid }"
      id="container"
      class="block w-full h-screen-min-header pb-10 overflow-x-hidden overflow-y-scroll"
    >
      <ReportPages :valid="valid"></ReportPages>
    </ValidationObserver>
  </div>
</template>

<script>
// Database
import DefaultReport from "@/database/defaults/report.js";
import report from "@/database/models/report.js";

// Config
import menu from "@/config/menu.js";

// Helpers
import uuid from "@/helpers/generateUuid.js";
import { sync } from "vuex-pathify";
import saveAs from "@/helpers/downloadJsonObject.js";
import generateReport from "@/helpers/generateReport.js";
import generatedReportTitle from "@/helpers/generateReportTitle.js";

export default {
  data() {
    return {
      node_env: process.env.NODE_ENV,
      saving: false,
    };
  },
  computed: {
    vuexReport: sync("report"),
    menu: sync("menu"),
    blade: sync("blade"),
    download: sync("download"),
  },
  mounted() {
    this.setVuexReport();
    this.setMenu();
  },
  methods: {
    setMenu() {
      if (this.menu.items !== undefined) {
        return;
      }

      this.menu.items = menu;
      this.menu.current = menu[0];
    },
    setVuexReport() {
      if (this.vuexReport !== undefined) {
        return;
      }

      this.vuexReport = { ...DefaultReport, id: uuid() };
    },
    async save() {
      if (!this.vuexReport) {
        return;
      }

      let message = this.vuexReport.materials.length + ' materials added, is this all? Are you sure?';
      if (!confirm(message)) {
        return;
      }

      this.saving = true;

      this.saveBlade();

      const row = await report.get(this.vuexReport.id);
      if (row === null) {
        await report.store(this.vuexReport.id, this.vuexReport);
      } else {
        await report.update(this.vuexReport.id, this.vuexReport);
      }

      if (this.download || this.node_env === "production") {
        const { report: generatedReport } = await generateReport(this.vuexReport.id);
        await saveAs(generatedReport, generatedReportTitle(this.vuexReport, true));
      }

      this.vuexReport = undefined;
      this.menu.items = undefined;
      this.menu.current = undefined;
      this.saving = false;
    },
    saveBlade() {
      if (this.blade === undefined) {
        return;
      }
      if (this.blade.id === undefined) {
        // Push to blade array if new
        this.blade.id = uuid();
        this.vuexReport.blades = [...this.vuexReport.blades, this.blade];
      } else {
        // Replace blade in array if edit
        this.vuexReport.blades = this.vuexReport.blades.map((blade) => {
          if (blade.id === this.blade.id) {
            return this.blade;
          }
          return blade;
        });
      }
      this.blade = undefined;
    },
  },
};
</script>
