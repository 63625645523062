<template>
    <div v-if="size" :style="{ padding: `0px ${padding}px` }">
        <div class="relative" :style="{ width: `${width - (padding * 2)}px` }">
            <ul class="flex justify-between">
                <li v-for="i in size + 1" class="relative flex flex-col items-center">
                    <template v-if="(i - 1) === 0 || (i - 1) % lineEvery === 0">
                        <span class="text-xs border-l border-gray-600 h-4"></span>
                        <span class="absolute top-15">
                            {{ i - 1 }}
                        </span>
                    </template>
                </li>
            </ul>
            <hr class="absolute" />
        </div>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
    props: {
        width: {
            type: Number,
            default: 600
        },
        padding: {
            type: Number,
            default: 0
        }
    },
    computed: {
        blade_size: sync('blade@blade_size'),
        size() {
            const size = parseInt(this.blade_size)

            if (!size || size < 1000) {
                return 0
            }

            return Math.round(size / 1000)
        },
        lineEvery() {
            if (this.size <= 40) {
                return 2;
            }

            if (this.size <= 80) {
                return 5;
            }

            return 10;
        }
    }
}
</script>

<style scoped>


ul {
    width: 100%;
}

hr {
    border: 1px solid #718096;
    margin: 0px;
    top: 8px;
    left: 0px;
    right: 0px;
    transform: translateY(-50%);
}
</style>
