<template>
  <div v-if="disabled">
    <span class="flex-shrink-0 block w-9">
      {{ label }}
    </span>
    {{ value }}
  </div>
  <div v-else>
    <VueDatePicker
      color="#295287"
      v-bind="$attrs"
      :value="value"
      inline
      @input="update"
      @change="change"
    >
      <template #activator="{ date }">
        <BaseInput
          ref="activator"
          :value="date"
          :disabled="disabled"
          :rules="rules"
          :label="label"
        ></BaseInput>
      </template>
    </VueDatePicker>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
import save from "@/helpers/save.js";

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    vuexReport: sync("report"),
    blade: sync("blade"),
  },
  methods: {
    update(e) {
      this.$emit("input", e);
    },
    async change() {
      await save(this.vuexReport, this.blade);
    },
  },
};
</script>
