export default {
    name: '',
    windward: {
        image: null,
        pos_y: -30,
        type: 'windward'
    },
    leeward: {
        image: null,
        pos_y: -30,
        type: 'leeward'
    },
    radius: null,
    damage_type_id: null,
    images: []
}
