<template>
  <li
    class="flex items-center justify-between w-full py-5 border-b border-gray-400"
  >
    <div class="items-center p-1 text-sm text-yellow-200 bg-yellow-800 rounded" v-if="item.status == 'returned'">
      <span>
        Returned report
      </span>
    </div>
    <span>
      {{ title }}
    </span>
    <span v-if="isReportUpToDate(item)" class="text-gray-600"> Latest </span>
    <span v-else class="text-gray-600">
      {{ item.version }}
    </span>
    <div class="flex">
      <BaseModel v-if="item.status !== 'returned'" v-model="removeModel">
        <template #default="{ openModel }">
          <BaseButton class="mr-5" color="red" @click="openModel">
            Delete
          </BaseButton>
        </template>

        <template #content>
          <h3 class="mb-6 text-2xl font-medium">
            Are you sure you want to delete report: "{{ title }}"?
          </h3>
          <div class="flex justify-between">
            <BaseButton class="mr-10" color="gray" @click="closeRemoveModel">
              Cancel
            </BaseButton>
            <BaseButton color="red" @click="remove"> Delete </BaseButton>
          </div>
        </template>
      </BaseModel>

      <BaseButton class="mr-5" color="orange" @click="edit"> Edit </BaseButton>

      <OnlineStatus>
        <template #default="{ isOnline }">
          <BaseModel v-if="item.status !== 'returned'" v-model="draftModel">
            <template #default>
              <BaseButton
                v-if="isOnline && user"
                color="gray"
                class="mr-5"
                @click="openDraftModel"
              >
                Export to server
              </BaseButton>
            </template>

            <template #content>
              <h3 class="mb-6 text-2xl font-medium">
                Do you want to export this unfinished report to the server? Once
                on the server, the report can be imported to another tablet
              </h3>
              <div class="w-full mb-6">
                <BaseTextArea
                  v-model="description"
                  label="Description"
                ></BaseTextArea>
              </div>
              <div class="flex justify-between">
                <BaseButton class="mr-10" color="gray" @click="closeDraftModel">
                  No thanks
                </BaseButton>
                <BaseButton
                  color="green"
                  :class="{ 'opacity-50': drafting }"
                  :disabled="drafting"
                  class="flex items-center"
                  @click="draft"
                >
                  Export to server
                  <img
                    v-if="drafting"
                    class="w-4 h-4 ml-4 spin"
                    src="@/assets/images/spinner.svg"
                  />
                </BaseButton>
              </div>
            </template>
          </BaseModel>
        </template>
      </OnlineStatus>

      <OnlineStatus>
        <template #default="{ isOnline }">
          <BaseModel v-model="sendModel">
            <template #default>
              <BaseButton
                v-if="isOnline && user"
                color="green"
                @click="openSendModel"
              >
                Finish
              </BaseButton>
            </template>

            <template #content>
              <h3 class="mb-6 text-2xl font-medium">
                Are you sure you want to send report: "{{ title }}" to the
                server?
              </h3>
              <div class="flex justify-between">
                <BaseButton class="mr-10" color="gray" @click="closeSendModel">
                  Cancel
                </BaseButton>
                <BaseButton
                  color="green"
                  :class="{ 'opacity-50': sending }"
                  :disabled="sending"
                  class="flex items-center"
                  @click="send"
                >
                  Finish
                  <img
                    v-if="sending"
                    class="w-4 h-4 ml-4 spin"
                    src="@/assets/images/spinner.svg"
                  />
                </BaseButton>
              </div>
            </template>
          </BaseModel>
        </template>
      </OnlineStatus>
    </div>
  </li>
</template>

<script>
// Database
import reportModel from "@/database/models/report.js";
import imageModel from "@/database/models/image.js";

// Config
import generateReportTitle from "@/helpers/generateReportTitle.js";

// Helpers
import { sync } from "vuex-pathify";
import generateReport from "@/helpers/generateReport.js";
import isReportUpToDate from "@/helpers/isReportUpToDate.js";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sending: false,
      drafting: false,
      removeModel: false,
      sendModel: false,
      description: "",
      draftModel: false,
    };
  },
  computed: {
    report: sync("report"),
    user: sync("auth/user"),
    errors: sync("errors"),
    title() {
      return generateReportTitle(this.item);
    },
  },
  methods: {
    isReportUpToDate: isReportUpToDate,
    edit() {
      this.report = this.item;
    },
    openRemoveModel() {
      this.removeModel = true;
    },
    closeRemoveModel() {
      this.removeModel = false;
    },
    openSendModel() {
      this.sendModel = true;
    },
    closeSendModel() {
      this.sendModel = false;
    },
    openDraftModel() {
      this.draftModel = true;
    },
    closeDraftModel() {
      this.draftModel = false;
    },
    remove() {
      reportModel.remove(this.item.id);
      this.$emit("change", this.item);
      this.closeRemoveModel();
    },
    async draft() {
      if (this.drafting) {
        return;
      }

      this.drafting = true;

      const { report, images } = await generateReport(this.item.id);

      report.materials = Object.values(report.materials).map((material) => {
        return material.model;
      });

      report.status = "draft";

      this.$axios
        .post("/api/drafts", report)
        .then(async (data) => {
          this.$toasted.show(data.data.message, {
            duration: 5000,
            position: "bottom-right",
            type: "success",
          });
          this.remove();

          const reports = await reportModel.keys();

          const allImages = await Promise.all(
            reports.map(async (report) => {
              const { images } = await generateReport(report, false);
              return images;
            })
          );

          const allImagesMapped = allImages.flatMap((images) => images);

          images.forEach(async (image) => {
            if (allImagesMapped.includes(image)) {
              return;
            }
            await imageModel.remove(image);
          });
        })
        .catch((err) => {
          if (!err.response) {
            return;
          }

          if (err.response.status === 401) {
            this.user = undefined;
            this.$toasted.show("Session Time-out", {
              duration: 5000,
              position: "bottom-right",
              type: "error",
            });
            return;
          }

          const errors = Object.keys(err.response.data.errors).map((key) => {
            return err.response.data.errors[key];
          });

          const allErrors = [].concat.apply([], errors);

          this.$toasted.show(err.response.data.message, {
            duration: 5000,
            position: "bottom-right",
            type: "error",
          });
          allErrors.forEach((error) => {
            this.$toasted.show(error, {
              duration: 5000,
              position: "bottom-right",
              type: "error",
            });
          });
          console.log({ err });
        })
        .finally(() => {
          this.closeDraftModel();

          this.drafting = false;
        });
    },
    async send() {
      if (this.sending) {
        return;
      }

      this.sending = true;
      const { report } = await generateReport(this.item.id);

      report.status = 'new';

      report.materials = Object.values(report.materials).map((material) => {
        return material.model;
      });

      this.$axios
        .post("/api/reports", report)
        .then(async (data) => {
          this.$toasted.show(data.data.message, {
            duration: 5000,
            position: "bottom-right",
            type: "success",
          });
          this.remove();
        })
        .catch((err) => {
          if (!err.response) {
            return;
          }

          if (err.response.status === 401) {
            this.user = undefined;
            this.$toasted.show("Session Time-out", {
              duration: 5000,
              position: "bottom-right",
              type: "error",
            });
            return;
          }

          const errors = Object.keys(err.response.data.errors).map((key) => {
            return err.response.data.errors[key];
          });

          const allErrors = [].concat.apply([], errors);

          this.$toasted.show(err.response.data.message, {
            duration: 5000,
            position: "bottom-right",
            type: "error",
          });
          allErrors.forEach((error) => {
            this.$toasted.show(error, {
              duration: 5000,
              position: "bottom-right",
              type: "error",
            });
          });
        })
        .finally(() => {
          this.closeSendModel();

          this.sending = false;
        });
    },
  },
};
</script>
