import Vuex from 'vuex'
import pathify, {
  make
} from 'vuex-pathify'
import createPersistedState from 'vuex-persistedstate'

pathify.options.mapping = 'simple'

const requireModule = require.context('~/store/', true, /\.js$/)

const generateModules = () => {
  const modules = {}
  let defaultState = {}

  requireModule.keys().forEach(fileName => {
    const name = fileName.replace('.js', '').replace('./', '').split('/')

    if (fileName === './index.js') {
      return
    }

    if (fileName === './root.js') {
      defaultState = getModule(fileName, false)
      return
    }

    insertModule(modules, name, fileName)
  })

  return {
    modules,
    state: defaultState
  }
}

function getModule (fileName, namespaced = true) {
  const {
    default: {
      modules,
      ...state
    },
    getters,
    actions,
    mutations
  } = requireModule(fileName)

  return {
    namespaced,
    state,
    mutations: {
      ...make.mutations(state),
      ...mutations
    },
    getters: {
      ...make.getters(state),
      ...getters
    },
    actions: {
      ...make.actions(state),
      ...actions
    },
    modules: []
  }
}

function insertModule (modules, [first, ...rest], fileName) {
  if (rest.length === 0) {
    modules[first] = getModule(fileName)
    return
  }
  insertModule(modules[first].modules, rest, fileName)
}

const {
  state: {
    state,
    mutations,
    actions,
    getters
  },
  modules
} = generateModules()

export default () => {
  return new Vuex.Store({
    plugins: [
      pathify.plugin,
      createPersistedState()
    ],
    state,
    mutations,
    actions,
    getters,
    modules
  })
}
