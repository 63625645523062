export default function ({ store, redirect, route, isHMR, app }) {
  if (isHMR) return

  // const isOnline = navigator.onLine
  // const isLoggedOut = !store.state.auth.user

  // if (isOnline && isLoggedOut && route.name !== 'login') {
  //   return redirect('/login')
  // }

  // if (!isOnline && route.name === 'login') {
  //   return redirect('/')
  // }

  store.state.auth.user && store.state.auth.user.token && app.$axios.setToken(store.state.auth.user.token, 'Bearer')
}
