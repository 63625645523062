import worktypeOptions from '@/config/options/worktype.js'

export default (report, slug) => {
  if (!report) {
    return
  }

  const worktype = worktypeOptions.find((type) => type.id === report.work_type_id)

  const name = (worktype) ? worktype.name : ''

  const title = `${name} report ${report.pad_number}`

  const totalTitle = `${report.wtg_id ? `${title} WTG ${report.wtg_id}` : title}`

  if (slug) {
    return totalTitle.replace(' ', '-')
  }

  return totalTitle
}
