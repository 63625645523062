<template>
  <div>
    <div class="flex justify-between mb-12">
      <h2 class="text-4xl font-medium">
        Materials
      </h2>
      <BaseButton color="green" @click="addImage">
        Add
      </BaseButton>
    </div>
    <ul class="flex flex-wrap w-full -mx-4">
      <li v-for="(image, i) in materials" :key="i" class="w-1/2 p-4">
        <BaseMaterialsImageUpload v-model="image.model" :materialId="image.id" @removeImage="removeImage"></BaseMaterialsImageUpload>
      </li>
    </ul>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import uuid from '@/helpers/generateUuid.js'

// Database
import DefaultImage from '@/database/defaults/image.js'
import imageModel from '@/database/models/image.js'

export default {
  computed: {
    materials: sync('report@materials')
  },
  methods: {
    addImage () {
      const newImage = { model: { ...DefaultImage, id: uuid() } }

      this.materials = [ ...this.materials, newImage ]
    },
    async removeImage (image) {
      this.materials = this.materials.filter(_image => _image.model.id !== image.id)

      await imageModel.remove(image.filename)
    }
  }
}
</script>
