<template>
  <div class="mt-12">
    <ul v-if="denieds.length || loading" class="w-full mb-32">
      <DeniedsListItem
        v-for="(denied, i) in denieds"
        :key="i"
        :denied="denied"
        @update="getDenieds"
      ></DeniedsListItem>
    </ul>
    <div
      v-else
      class="flex items-center justify-center w-full py-6 border rounded"
    >
      No returned reports to import
    </div>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  data() {
    return {
      denieds: [],
      loading: true,
    };
  },
  mounted() {
    this.getDenieds();
  },
  computed: {
    user: sync("auth/user"),
  },
  methods: {
    async getDenieds() {
      this.$axios
        .get("/api/returneds")
        .then((res) => {
          this.denieds = res.data;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.user = undefined;
            this.$toasted.show("Session Time-out", {
              duration: 5000,
              position: "bottom-right",
              type: "error",
            });
            return;
          }
          console.log(error.response);
        });
    },
  },
};
</script>
