<template>
  <div class="w-full pr-48">
    <div v-if="status == 'returned'">
      <span class="p-1 text-sm text-yellow-200 bg-yellow-800 rounded">
        Returned report
      </span>
    </div>
    <h2 class="text-4xl font-medium">Start</h2>
    <div class="flex flex-col gap-2" v-if="status == 'returned'">
      <div>
        <span class="text-xl font-medium">Reason for returning:</span>
        <p>{{ report.status_denied_message }}</p>
      </div>
      <div class="w-full h-px my-4 bg-gray-200"></div>
    </div>
    <div class="flex mt-12">
      <div class="w-3/4 pr-5">
        <BaseSelect
          v-model="work_type_id"
          name="work_type_id"
          tabindex="0"
          :options="worktypeOptions"
          label="Work Type"
          class="block"
        ></BaseSelect>
        <BaseImage
          v-model="report_image"
          name="report_image"
          tabindex="1"
          :rules="rules('report_image')"
          class="mt-12"
        ></BaseImage>
      </div>
      <div class="w-1/4 pl-5">
        <BaseDatePicker
          v-model="report_date"
          name="report_date"
          tabindex="2"
          label="Report date"
          :rules="rules('report_date')"
        ></BaseDatePicker>
      </div>
    </div>
  </div>
</template>

<script>
// Config
import worktypeOptions from "@/config/options/worktype.js";

// Helpers
import rules from "@/helpers/rules.js";
import { sync } from "vuex-pathify";

export default {
  computed: {
    report: sync("report"),
    status: sync("report@status"),
    work_type_id: sync("report@work_type_id"),
    report_image: sync("report@report_image"),
    report_date: sync("report@report_date"),
    project_title: sync("report@project_title"),
    worktypeOptions: () => worktypeOptions,
  },
  methods: {
    rules: rules("report"),
  },
};
</script>
