export default [{
  id: 'Crane',
  name: 'Crane'
},
{
  id: 'Sky Climber',
  name: 'Sky Climber'
},
{
  id: 'Rope access',
  name: 'Rope access'
},
{
  id: 'Ground work',
  name: 'Ground work'
}
]
