<template>
  <div class="w-full h-64 border rounded border-gray-500 flex justify-center items-center">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>
