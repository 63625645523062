<template>
  <ValidationProvider v-click-outside="disableFocus" v-slot="{ invalid }" :rules="rules">
    <label class="flex items-center w-full">
      <span class="flex flex-shrink-0 w-9">
        {{ label }}
        <span v-if="isRequired" class="text-red-800">
          *
        </span>
      </span>
      <input
        :disabled="disabled"
        ref="input"
        @focus="focus = true"
        :class="{ 'border-red-400 border-2': invalid ,'opacity-50 border-2': disabled }"
        class="w-full px-4 py-3 bg-gray-200 border border-gray-400 rounded disabled:bg-red-500 "
        v-bind="$attrs"
        :value="value"
        @input="update"
        @change="change"
      >
    </label>
    <SpecialSymbols v-if="showSymbols && focus" @select="selectSymbol"></SpecialSymbols>
  </ValidationProvider>
</template>

<script>
import { sync } from 'vuex-pathify'
import save from '@/helpers/save.js'

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    showSymbols: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focus: false
    }
  },
  computed: {
    vuexReport: sync('report'),
    blade: sync('blade'),
    isRequired () {
      return this.rules.split('|').includes('required')
    },
  },
  methods: {
    update (e) {
      this.$emit('input', e.target.value)
    },
    selectSymbol(symbol) {
      this.$emit('input', this.value + symbol)
      this.$refs.input.focus()
    },
    disableFocus() {
      this.focus = false
    },
    async change () {
      if (this.vuexReport) {
        await save(this.vuexReport, this.blade)
      }
    }
  }
}
</script>
