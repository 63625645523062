export default {
  version: process.env.VERSION,
  windfarm_name: '',
  work_type_id: 1,
  service_interval_id: 1,
  user_id: '',
  wtg_id: '',
  project_title: '',
  report_date: (() => {
    const date = new Date()
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  })(),
  report_image: '',
  pad_number: '',
  turbine_type: '',
  access_type: 'Crane',
  kwl: '',
  kwh: '',
  hub_height: '',
  tablet_used: '',
  address: '',
  client: '',
  nrc_number: '',
  contact: '',
  service_engineers: '',
  blades: [],
  status: '',
  materials: [],
}
