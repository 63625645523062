<template>
  <div>
    <h2 class="mb-5 font-bold text-3xl">
      Login
    </h2>
    <div class="mb-5">
      <BaseInput v-model="email" label="E-mailadres" type="text" rules="required"></BaseInput>
    </div>
    <div class="mb-5">
      <BaseInput v-model="password" label="Wachtwoord" type="password" rules="required"></BaseInput>
    </div>

    <div class="flex justify-end">
      <button class="px-8 py-3 bg-gray-900 text-white rounded hover:cursor-pointer" @click="login">
        Login
      </button>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  layout: 'login',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    user: sync('auth/user')
  },
  methods: {
    login () {
      this.$axios.$post('/api/authorize', {
        email: this.email,
        password: this.password
      }).then(data => {
        this.user = data
        this.$toasted.show('Login successfull ', {
          duration: 5000,
          position: 'bottom-right',
          type: 'success'
        })
        this.$axios.setToken(this.user.token, 'Bearer')
      }).catch(err => {
        this.$toasted.show(err.response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          type: 'error'
        })
      })
    }
  }
}

</script>
