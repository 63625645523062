<template>
    <div class="relative w-1/6 h-screen-min-header bg-gray-300">
        <ul class="overflow-scroll h-70vh">
            <li v-for="(item, i) in menu.items" :key="i">
                <div :class="{ 'bg-gray-500': (item.title === menu.current.title) && (currentBlade === undefined) }"
                    class="flex items-center w-full p-2" @click="changePage(item)">
                    <span class="block w-3 h-3 mr-3 rounded-full"
                        :class="{ 'bg-red-500': !item.validated && !item.disableValidation, 'bg-green-500': item.validated && !item.disableValidation }"></span>
                    {{ item.title }}
                </div>
                <ul v-if="item.isInspection" class="w-full">
                    <li v-for="(blade, j) in report.blades" :key="j" class="w-full">
                        <template v-if="currentBlade && blade.id === currentBlade.id">
                            <span class="block w-full p-2 pl-10 bg-gray-500">
                                {{ serviceInterval(interval) }}: {{ currentBlade.name }}
                            </span>
                            <ul>
                                <li v-for="(damage, k) in currentBlade.damages" :key="k" class="w-full p-2 pl-12" :class="{ 'bg-gray-400': damage.inView }" @click.prevent="goToDamage(blade, damage, item)">
                                    {{ k + 1 }}: {{ damage.name }}
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <span class="block w-full p-2 pl-10"
                                :class="{ 'bg-gray-400': currentBlade && blade.id === currentBlade.id }"
                                @click="goToBlade(blade, item)">
                                {{ serviceInterval(interval) }}: {{ blade.name }}
                            </span>
                            <ul>
                                <li v-for="(damage, k) in blade.damages" :key="k" class="w-full p-2 pl-12" @click.prevent="goToDamage(blade, damage, item)">
                                    {{ k + 1 }}: {{ damage.name }}
                                </li>
                            </ul>
                        </template>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="absolute bottom-60">
            <label v-if="node_env !== 'production'" class="flex items-center p-5">
                <input v-model="download" class="mr-3" type="checkbox">
                <span>Download <br> export file</span>
            </label>
            <div class="p-5">
                <BaseButton :disabled="saving" color="green" @click="(e) => $emit('save')">
                    Save Report
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
// Helpers
import { sync, get } from 'vuex-pathify'

import damageTypes from '@/config/options/damagetype.js'
import uuid from '@/helpers/generateUuid.js'

import serviceInterval from '@/helpers/serviceInterval.js'

export default {
    props: {
        saving: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            node_env: process.env.NODE_ENV
        }
    },
    computed: {
        interval: get('report@service_interval_id'),
        report: sync('report'),
        currentBlade: sync('blade'),
        menu: sync('menu'),
        download: sync('download'),
        damageTypes: () => damageTypes
    },
    methods: {
        serviceInterval,
        changePage(item, checkBlade = true) {
            if (this.currentBlade && checkBlade) {
                this.saveBlade()
            }
            this.menu.current = item
        },
        getDamageType(id) {
            return this.damageTypes.find((type) => type.id === parseInt(id))
        },
        goToDamage(blade, damage, item) {
            if (this.currentBlade !== undefined && this.currentBlade.id !== blade.id) {
                this.saveBlade()
            }

            this.currentBlade = blade
            this.changePage(item, false)

            this.currentBlade.damages = this.currentBlade.damages.map(damage => {
                damage.inView = false
                return damage;
            })

            this.$nextTick(() => {
                const $damage = document.querySelector(`[data-damage="${damage.id}"]`);
                $damage.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            });
        },
        goToBlade(blade, item) {
            if (this.currentBlade !== undefined) {
                this.saveBlade()
            }

            this.currentBlade = blade
            this.changePage(item, false)
        },
        saveBlade() {
            if (this.currentBlade.id === undefined) {
                // Push to blade array if new
                this.currentBlade.id = uuid()
                this.report.blades = [...this.report.blades, this.currentBlade]
            } else {
                // Replace blade in array if edit
                this.report.blades = this.report.blades.map(blade => {
                    if (blade.id === this.currentBlade.id) {
                        return this.currentBlade
                    }
                    return blade
                })
            }
            this.currentBlade = undefined
        }
    }
}
</script>
