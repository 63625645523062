<template>
  <ValidationProvider v-slot="{ errors, classes, invalid }" :rules="rules">
    <label class="flex items-center w-full">
      <span class="flex-shrink-0 block w-9">
        {{ label }}
        <span v-if="isRequired" class="text-red-800"> * </span>
      </span>
      <select
        :disabled="disabled"
        :class="{ classes, 'border-red-400 border-2': invalid, 'opacity-50 border-2': disabled  }"
        class="w-full h-12 px-4 py-3 bg-gray-200 border border-gray-400 rounded"
        v-bind="$attrs"
        :value="value"
        @input="update"
        @change="change"
      >
        <option v-for="(option, i) in options" :key="i" :value="option.id">
          {{ option.name }}
        </option>
      </select>
    </label>
  </ValidationProvider>
</template>

<script>
import { sync } from "vuex-pathify";
import save from "@/helpers/save.js";

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRequired() {
      return this.rules.split("|").includes("required");
    },
    vuexReport: sync("report"),
    blade: sync("blade"),
  },
  mounted() {
    if (!this.value) {
      this.$emit("input", this.options[0].id);
    }
  },
  methods: {
    update(e) {
      const value =
        this.input instanceof Number
          ? parseInt(e.target.value)
          : e.target.value;
      this.$emit("input", value);
    },
    async change() {
      await save(this.vuexReport, this.blade);
    },
  },
};
</script>
