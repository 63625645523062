export default {
  name: '',
  vui: '',
  generic_item: '',
  item: '',
  sn: '',
  blade_size: '',
  blade_image: '',
  blade_image_description: '',
  damages: []
}
