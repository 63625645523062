import start from '@/components/reports/pages/StartPage.vue'
import general from '@/components/reports/pages/GeneralPage.vue'
import windfarm from '@/components/reports/pages/WindfarmPage.vue'
import inspection from '@/components/reports/pages/InspectionPage.vue'
import materials from '@/components/reports/pages/MaterialsPage.vue'

const components = {
  start,
  general,
  windfarm,
  inspection,
  materials
}

export default (name) => components[name]
