<template>
  <div>
    <div v-if="blade">
      <Blade></Blade>
    </div>
    <div v-else class="w-full">
      <div class="flex items-center justify-between">
        <h2 class="text-4xl font-medium">
          Inspection
        </h2>
        <BaseButton color="green" @click="addBlade">
          Add
        </BaseButton>
      </div>
      <BladeList></BladeList>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

// Database
import defaultBlade from '@/database/defaults/blade.js'

export default {
  computed: {
    blade: sync('blade'),
    items: sync('menu/items'),
    current: sync('menu/current')
  },
  methods: {
    addBlade () {
      this.blade = { ...defaultBlade, damages: [] }
    }
  }
}
</script>
