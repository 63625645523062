import localforage from 'localforage'
import metadata from '@/database/models/metadata.js'

const image = localforage.createInstance({
  name: 'images'
})

const store = async (key, data, metadataObject = {}) => {
  await metadata.store(key, {
    date: Date.now(),
    ...metadataObject
  })

  image.setItem(key, data)
  return data
}

const get = (key) => {
  return image.getItem(key)
}

const getMetaData = async (key) => {
  const data = await metadata.get(key)

  return data
}

const update = (key, data) => {
  return new Promise(async (resolve, reject) => {
    await metadata.update(key, {
      date: Date.now()
    })
    image.setItem(key, data)
    resolve(data);
  });
}

const remove = async (key) => {
  return new Promise((resolve, reject) => {
    image.removeItem(key, (err, value) => resolve(value))
  })
}

const length = () => {
  return new Promise((resolve, reject) => {
    image.length((err, val) => resolve(val))
  })
}

const clear = () => {
  return new Promise((resolve, reject) => {
    image.clear((err, val) => resolve(val))
  })
}

const keys = () => {
  return new Promise((resolve, reject) => {
    image.keys((err, val) => resolve(val))
  })
}

export default {
  get,
  getMetaData,
  store,
  update,
  remove,
  length,
  keys,
  clear
}
