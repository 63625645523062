<template>
  <ul class="w-full">
    <li v-for="(item, i) in items" :key="i" class="flex justify-between w-full border-b border-gray-400 py-5">
      <slot :item="item" :index="i"></slot>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
