<template>
  <div class="w-full">
    <h2 class="text-4xl font-medium">
      General
    </h2>
    <div class="w-full mt-12 mb-6">
      <BaseInput v-model="project_title" name="project_title" tabindex="1" label="Project Number" rules="min:1|max:255"></BaseInput>
    </div>
    <div class="flex mt-12">
      <div class="w-1/2 pr-5">
        <BaseInput v-model="wtg_id" name="wtg_id" :showSymbols="true" tabindex="1" label="WTG ID" rules="required|min:1|max:255"></BaseInput>
      </div>
      <div class="w-1/2 pl-5">
        <BaseInput v-model="pad_number" name="pad_number" :showSymbols="true" tabindex="2" label="Pad Number" rules="required|min:1|max:255"></BaseInput>
      </div>
    </div>
    <div class="flex mt-12">
      <div class="w-1/2 pr-5">
        <BaseInput v-model="turbine_type" name="turbine_type" :showSymbols="true" tabindex="3" label="Turbine type" :rules="rules('turbine_type')"></BaseInput>
      </div>
      <div class="w-1/2 pl-5">
        <BaseSelect v-model="service_interval_id" name="service_interval_id" :showSymbols="true" tabindex="4" :options="serviceIntervalOptions" label="Service Interval"></BaseSelect>
      </div>
    </div>
    <div class="flex mt-12">
      <div class="w-1/2 pr-5">
        <BaseInput v-model="kwl" name="kwl" :showSymbols="true" tabindex="5" type="number" label="KWL" :rules="rules('kwl')"></BaseInput>
      </div>
      <div class="w-1/2 pl-5">
        <BaseInput v-model="kwh" name="kwh" :showSymbols="true" tabindex="6" type="number" label="KWH" :rules="rules('kwh')"></BaseInput>
      </div>
    </div>
    <div class="flex mt-12">
      <div class="w-1/2 pr-5">
        <BaseInput v-model="hub_height" name="hub_height" :showSymbols="true" tabindex="7" label="Hub Height" :rules="rules('hub_height')"></BaseInput>
      </div>
      <div class="w-1/2 pl-5">
        <BaseInput v-model="tablet_used" :showSymbols="true" tabindex="8" label="Tablet used" :rules="rules('tablet_used')"></BaseInput>
      </div>
    </div>
  </div>
</template>

<script>
// Config
import serviceIntervalOptions from '@/config/options/serviceInterval.js'

// Helpers
import { sync } from 'vuex-pathify'
import rules from '@/helpers/rules.js'

export default {
  computed: {
    wtg_id: sync('report@wtg_id'),
    pad_number: sync('report@pad_number'),
    turbine_type: sync('report@turbine_type'),
    service_interval_id: sync('report@service_interval_id'),
    kwl: sync('report@kwl'),
    kwh: sync('report@kwh'),
    hub_height: sync('report@hub_height'),
    tablet_used: sync('report@tablet_used'),
    project_title: sync('report@project_title'),
    report: sync('report'),
    serviceIntervalOptions: () => serviceIntervalOptions
  },
  methods: {
    rules: rules('report'),
  }
}
</script>
