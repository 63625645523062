import report from '@/config/validationRules/report.js'
import blade from '@/config/validationRules/blade.js'
import damage from '@/config/validationRules/damage.js'
import damageImage from '@/config/validationRules/damageImage.js'
import materialImage from '@/config/validationRules/materialImage.js'

const rulesets = {
  report,
  blade,
  damage,
  damageImage,
  materialImage
}

export default (ruleset) => {
  return (field) => {
    return rulesets[ruleset][field]
  }
}
