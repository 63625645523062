<template>
  <li class="flex items-center justify-between w-full py-5 border-b border-gray-400">
    <span class="block w-full max-w-xs">
      Name: {{ draft.name }}
    </span>
    <span class="text-gray-600">
      Created At: {{ draft.created_at }}
    </span>

    <div class="flex">
      <BaseModel v-model="importModel">
        <template #default="{ openModel }">
          <BaseButton class="mr-5" color="green" @click="openModel">
            Import from server
          </BaseButton>
        </template>

        <template #content>
          <h3 class="mb-6 text-2xl font-medium">
            Are you sure you want to import report: "{{ draft.name }}" from the server?
          </h3>
          <div class="flex justify-between">
            <BaseButton class="mr-10" color="gray" @click="closeModel">
              No, thanks
            </BaseButton>
            <BaseButton color="green" :disabled="importingReport" @click="importReport">
              Yes, import
            </BaseButton>
          </div>
        </template>
      </BaseModel>
    </div>
  </li>
</template>

<script>
import importReport from '@/helpers/importReport'
import generateReport from '@/helpers/generateReport'
import reportModel from '@/database/models/report.js'
import imageModel from '@/database/models/image.js'
import metadataModel from '@/database/models/metadata.js'

export default {
  props: {
    draft: {
      type: Object,
      requried: true
    }
  },
  data () {
    return {
      importModel: false,
      importingReport: false
    }
  },
  methods: {
    openModel () {
      this.importModel = true
    },
    closeModel () {
      this.importModel = false
    },
    importReport () {
      if (this.importingReport) {
        return;
      }

      this.importingReport = true;
      this.$axios.get(`/api/drafts/${this.draft.id}`).then(async res => {
        console.log(res.data);
        await importReport(res.data)

        const { report, images } = await generateReport(res.data.id)

        console.log(report);

        return this.$axios.put(`/api/drafts/${this.draft.id}/check`, { report }).then(res => {
          this.$toasted.show(res.data.message, {
            duration: 5000,
            position: 'bottom-right',
            type: 'success'
          })
        }).catch(async err => {
          this.$toasted.show(err.response.data.message, {
            duration: 5000,
            position: 'bottom-right',
            type: 'error'
          })
          await reportModel.remove(report.id)

          images.forEach(async (image) => {
            await imageModel.remove(image)
            await metadataModel.remove(image)
          })
        })
      }).catch(err => {
        this.$toasted.show(err.response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          type: 'error'
        })
      }).finally(() => {
        this.closeModel()
        this.importingReport = false;

        this.$emit('update')
      })
    }
  }
}
</script>
