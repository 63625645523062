<template>
  <div>
    <slot :openModel="openModel"></slot>
    <div v-if="open" :class="{ 'flex items-center': center }" class="fixed top-0 left-0 right-0 bottom-0 z-50 bg-model px-32 overflow-y-scroll" @click.self="closeModel">
      <div class="container mx-auto my-20 bg-white p-8">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change',
    prop: 'open'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModel () {
      this.$emit('change', false)
    },
    openModel () {
      this.$emit('change', true)
    }
  }
}
</script>
