<template>
    <div>
        <div class="flex justify-between w-full">
            <h3>{{ label }}</h3>
            <BaseButton color="red" @click="clear">
                Clear
            </BaseButton>
        </div>
        <v-stage ref="stage" :config="stageSize">
            <v-layer ref="layer" @click="canvasTappedEvent" @tap="canvasTappedEvent">
                <v-image :config="{ image: imageToRender, y: padding, x: padding }" />
                <v-image
                    ref="circle"
                    :image="crossImageForSelection"
                    :config="{
                        width: crossSize,
                        height: crossSize,
                        draggable: true,
                        x: this.x,
                        y: value.pos_y
                    }"
                    :dragBoundFunc="dragBoundFunc"
                    @dragend="circleDraggedEvent"
                />

                <v-image
                    v-for="otherDamage in otherDamages"
                    :key="otherDamage.id"
                    :image="crossImageForOtherSelections"
                    :config="{
                        width: crossSize,
                        height: crossSize,
                        draggable: false,
                        x: calculateX(otherDamage.radius),
                        y: otherDamage[type].pos_y
                    }"
                />

            </v-layer>
        </v-stage>
        <BladeSizeScale :width="stageSize.width" :padding="padding" class=" bottom-24 bottom-45" />
    </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import save from '@/helpers/save.js'
import BladeSizeScale from '@/components/blades/BladeSizeScale.vue'

export default {
    components: {
        BladeSizeScale
    },
    model: {
        event: 'change'
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                image: null,
                pos_y: -30
            })
        },
        label: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        damageRadius: {
            type: Number,
            default: () => -30
        },
        bladeSize: {
            type: Number|String,
            default: () => 0
        },
        damage: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            crossSize: 30,
            padding: 15,
            crossImageForSelection: null,
            crossImageForOtherSelections: null,
            stageSize: {
                width: 630,
                height: 110
            },
            imageToRender: null,
        }
    },
    mounted() {
        this.loadImages();
    },
    computed: {
        vuexReport: sync('report'),
        blade: sync('blade'),
        x() {
            return this.calculateX(this.damageRadius);
        },
        otherDamages() {
            return this.blade.damages.filter((damage) => damage[this.type].pos_y !== -30 && damage.id !== this.damage.id)
        }
    },
    created() {
        const image = new window.Image()
        image.src = this.image
        const aspectRatio = (530 / 83)

        const width = this.stageSize.width - (this.padding * 2);

        const height = (width / aspectRatio)
        this.stageSize.height = height + (this.padding * 2)

        image.setAttribute('width', width)
        image.setAttribute('height', height)
        image.onload = () => {
            this.imageToRender = image
        }
    },
    watch: {
        'damageRadius'() {
            this.emitChanges(parseInt(this.x), this.value.pos_y)
        },
        'bladeSize'() {
            this.emitChanges(parseInt(this.x), this.value.pos_y)
        },
    },
    methods: {
        calculateX(radius) {
            const base = (radius / parseInt(this.bladeSize)) * (this.stageSize.width - (this.padding * 2));
            const crossSize = this.crossSize / 2;

            return base - crossSize + this.padding;
        },
        clear() {
            this.$emit('change', {
                image: null,
                pos_y: -30,
                type: this.type
            })
        },
        canvasTappedEvent(e) {
            const { y } = this.$refs.stage.getStage().getPointerPosition()

            const x = parseInt(this.x);
            const circle = this.$refs.circle

            if (!circle) {
                this.$nextTick(() => {
                    this.emitChanges(x, y)
                })
                return
            }

            circle.getStage().to({
                x,
                y,
                duration: 0.08,
                onFinish: () => {
                    this.emitChanges(x, y)
                    save(this.vuexReport, this.blade)
                }
            })
        },
        generatePicture() {
            const canvas = this.$refs.stage.getStage()
            return canvas.toDataURL()
        },
        circleDraggedEvent(e) {
            const { y, x } = this.$refs.circle.getStage().getPosition()
            const radius = (x - (this.crossSize / 2)) / this.stageSize.width * parseInt(this.bladeSize)

            this.$emit('changeRadius', radius)
            this.emitChanges(parseInt(radius), y)
        },
        emitChanges(x, y) {
            const image = this.generatePicture()
            this.$emit('change', { pos_y: parseInt(y), image, type: this.type })
        },
        dragBoundFunc(pos) {
            return {
                x: this.x,
                y: pos.y
            };
        },
        async loadImages() {
            this.crossImageForSelection = await this.loadSvg(`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>`);
            this.crossImageForOtherSelections = await this.loadSvg(`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9CA3AF" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>`);
        },
        async loadSvg(source) {
            return new Promise((resolve, reject) => {
                const svgBlob = new Blob([source], { type: 'image/svg+xml' });
                const url = URL.createObjectURL(svgBlob);
                const img = new Image();
                img.onload = () => {
                    URL.revokeObjectURL(url);
                    resolve(img);
                };
                img.src = url;
            });
        }
    }
}
</script>
