<template>
  <div class="w-full">
    <h2 class="text-4xl font-medium">
      Windfarm: {{ windfarm_name }}
    </h2>
    <div class="w-full mt-12">
      <BaseInput v-model="windfarm_name" name="windfarm_name" tabindex="1" :rules="rules('windfarm_name')" label="Name"></BaseInput>
    </div>
    <div class="w-full mt-12">
      <BaseInput v-model="address" name="address" label="Address" :rules="rules('address')"></BaseInput>
    </div>
    <div class="w-full mt-12">
      <BaseSelect v-model="access_type" name="access_type" :showSymbols="true" label="Access Type" :options="accessTypes" :rules="rules('access_type')"></BaseSelect>
    </div>
    <div class="flex mt-12">
      <div class="w-1/2 pr-5">
        <BaseInput v-model="client" name="clients" label="Client" :rules="rules('client')"></BaseInput>
      </div>
      <div class="w-1/2 pl-5">
        <BaseInput v-model="ncr_number" name="ncr_number" :showSymbols="true" label="NCR Number" :rules="rules('ncr_number')"></BaseInput>
      </div>
    </div>
    <div class="w-full mt-12">
      <BaseInput v-model="contact" name="contact" label="Contact" :rules="rules('contact')"></BaseInput>
    </div>
    <div class="w-full mt-12">
      <BaseInput v-model="service_engineers" name="service_engineers" label="Service Technicians" :rules="rules('service_engineers')"></BaseInput>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

import rules from '@/helpers/rules.js'
import accessTypes from '@/config/options/accesstype.js'

export default {
  computed: {
    address: sync('report@address'),
    client: sync('report@client'),
    ncr_number: sync('report@ncr_number'),
    contact: sync('report@contact'),
    service_engineers: sync('report@service_engineers'),
    windfarm_name: sync('report@windfarm_name'),
    report: sync('report'),
    access_type: sync('report@access_type'),
    accessTypes: () => accessTypes
  },
  methods: {
    rules: rules('report'),
  }
}
</script>
