<template>
  <div class="mt-12">
    <ul v-if="items.length" class="w-full mb-32">
      <ReportsListItem v-for="(item, i) in items" :key="i" :item="item" @change="update"></ReportsListItem>
    </ul>
    <div v-else class="flex items-center justify-center w-full py-6 border rounded">
      No reports on tablet
    </div>
  </div>
</template>

<script>
// Database
import reports from '@/database/models/report.js'

export default {
  data () {
    return {
      items: []
    }
  },
  mounted () {
    this.update()
  },
  methods: {
    async update () {
      this.items = await reports.index()
      this.$emit('items-changed', this.items)
    }
  }
}
</script>
