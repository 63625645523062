export default [
  {
    id: 1,
    name: 'Nacelle repair'
  },
  {
    id: 2,
    name: 'Blade maintenance'
  },
  {
    id: 3,
    name: 'Blade repair'
  },
  {
    id: 4,
    name: 'Blade inspection (ground)'
  },
  {
    id: 5,
    name: 'Blade inspection (camera)'
  },
  {
    id: 6,
    name: 'Blade inspection (drone)'
  },
  {
    id: 7,
    name: 'Tower repair'
  },
  {
    id: 8,
    name: 'Tower inspection'
  },
  {
    id: 9,
    name: 'Hub repair'
  },
  {
    id: 10,
    name: 'Component cleaning'
  }
]
