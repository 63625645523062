
import reportModel from '@/database/models/report.js'
import imageModel from '@/database/models/image.js'
import metadataModel from '@/database/models/metadata.js'

export default (uuid, replaceImage = true) => {
  return new Promise(async (resolve, reject) => {
    let report = await reportModel.get(uuid)

    const imageKeys = [
      'report_image',
      'blade_image',
      'filename'
    ]

    const images = []

    const newReport = await jsonValueReplacer(report, async (prop, val) => {

      if (val === null) {
        return val;
      }

      if (!imageKeys.includes(prop)) {
        return val;
      }
      // Wordt aangeroepen in jsonValueReplacer (stukje callback()), als code hier komt is het een image

      images.push(val);

      if (!replaceImage) {
        return val;
      }

      const image = await imageModel.get(val)
      const metadata = await metadataModel.get(val)

      return {
        image,
        ...metadata
      }
    })

    resolve({ report: newReport, images })
  })
}

const jsonValueReplacer = async (obj, callback) => {
  const modifiedObj = {}
  for (const key in obj) {
    let val
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      val = await jsonValueReplacer(obj[key], callback)
    } else {
      val = await callback(key, obj[key])
    }
    modifiedObj[key] = val
  }

  return modifiedObj
}
