import localforage from 'localforage'

const report = localforage.createInstance({
  name: 'reports'
})

const index = async () => {
  const items = []
  await report.iterate((value, key, iterationNumber) => {
    items.push(value)
  })
  return items
}

const store = (key, data) => {
  report.setItem(key, data)
  return data
}

const get = (key) => {
  return report.getItem(key)
}

const update = async (key, newData) => {
  const Combined = Object.assign(await get(key), newData)
  report.setItem(key, Combined)
  return Combined
}

const remove = async (key) => {
  return new Promise((resolve, reject) => {
    report.removeItem(key, (err, value) => resolve(value))
  })
}

const length = () => {
  return new Promise((resolve, reject) => {
    report.length((err, val) => resolve(val))
  })
}

const keys = () => {
  return new Promise((resolve, reject) => {
    report.keys((err, val) => resolve(val));
  });
};

export default {
  index,
  get,
  store,
  update,
  remove,
  length,
  keys
}
