import serviceIntervalOptions from '@/config/options/serviceInterval.js'

export default (intervalID) => {
  const id = parseInt(intervalID)

  const serviceInterval = serviceIntervalOptions.find((interval) => id === interval.id)

  if (!serviceInterval) {
    return 'Blade'
  }

  const [ prefix ] = serviceInterval.name.split(' ')

  return prefix
}
