<template>
  <div>
    <div v-if="disabled">
      <div
        class="relative w-full h-64 bg-gray-200 border border-gray-400 rounded"
        :style="{ background: `url(${image}) center center/cover no-repeat` }"
      >
        <div
          class="absolute z-40 w-full h-full bg-gray-100 rounded opacity-50"
        ></div>
      </div>
    </div>
    <div v-else>
      <ValidationProvider
        v-slot="{ invalid }"
        class="block mt-5"
        :rules="rules"
      >
        <div
          :class="{
            'border-red-400 border-2': invalid,
            'border-gray-400 border': !invalid,
          }"
          class="relative w-full h-64 bg-gray-200 rounded"
          :style="{ background: `url(${image}) center center/cover no-repeat` }"
        >
          <div
            v-if="value"
            class="flex items-center justify-center w-full h-full"
            :class="{ ' bg-gray-200': !value }"
          >
            <div class="absolute top-0 right-0 z-30 flex">
              <button
                class="px-4 py-2 bg-green-400 focus:outline-none"
                @click="viewModel = true"
              >
                View
              </button>
              <label class="px-4 py-2 bg-orange-400">
                Take new image
                <input
                  ref="input"
                  type="file"
                  class="hidden"
                  v-bind="$attrs"
                  accept="image/*"
                  @change="update"
                />
              </label>
              <span
                class="px-4 py-2 bg-orange-200"
                @click="selectImageFromMediaLibrary = true"
                >Select from media library</span
              >
            </div>
            {{ label }}
            <img
              v-if="loading"
              class="w-12 h-12 opacity-25 spin"
              src="@/assets/images/spinner.svg"
              alt="spinner"
            />
            <input type="text" class="hidden" :value="value" />
          </div>

          <div
            v-else
            class="flex items-center justify-center w-full h-full bg-gray-200"
          >
            <label class="mr-5">
              {{ label }}
              <img
                v-if="loading"
                class="w-12 h-12 opacity-25 spin"
                src="@/assets/images/spinner.svg"
              />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <input
                ref="input"
                type="file"
                class="hidden"
                v-bind="$attrs"
                @change="update"
              />
              <input type="text" class="hidden" :value="value" />
            </label>
            <span @click="selectImageFromMediaLibrary = true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <div
            v-if="metadata && metadata.date"
            class="w-full mb-2 text-xs italic text-right"
          >
            {{ formatDate(metadata.date) }}
          </div>
        </div>
      </ValidationProvider>
      <BaseModel v-model="viewModel">
        <template #content>
          <div class="flex justify-end mb-8">
            <BaseButton color="gray" @click="viewModel = false">
              Close
            </BaseButton>
          </div>
          <img :src="image" />
        </template>
      </BaseModel>
      <BaseModel v-model="selectImageFromMediaLibrary">
        <template #content>
          <MediaLibrary @selected="setImage" />
        </template>
      </BaseModel>
    </div>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

import image from "@/database/models/image.js";
import metadata from "@/database/models/metadata.js";
import compressor from "@/helpers/compressor.js";

import generatedReportTitle from "@/helpers/generateReportTitle.js";

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      image: "",
      loading: false,
      viewModel: false,
      selectImageFromMediaLibrary: false,
    };
  },
  computed: {
    report: sync("report"),
  },
  watch: {
    value: {
      handler: "renderImage",
      immediate: true,
    },
  },
  methods: {
    async renderImage() {
      this.image = "";
      this.metadata = "";
      if (this.value) {
        this.loading = true;
        this.image = await image.get(this.value);
        this.metadata = await metadata.get(this.value);
        this.loading = false;
      }
    },
    async update(e) {
      const { input } = this.$refs;
      this.image = "";
      this.loading = true;

      const file = input.files[0];

      if (!file) {
        return;
      }

      const compressedImage = await compressor(file, 0.6);

      const imageBlob = await this.reader(compressedImage);

      this.saveImageToDevice(imageBlob);

      const uuid = Math.floor(Date.now() / 1000).toString();

      this.image = imageBlob;
      this.loading = false;

      await image.update(uuid, imageBlob);

      this.$emit("input", uuid);
    },
    saveImageToDevice(src) {
      const link = document.createElement("a");
      link.href = src;

      const date = this.getCurrentDate();
      const timestamp = new Date().getTime();

      const name = `${generatedReportTitle(
        this.report,
        true
      ).trimRight()}-${date}-${timestamp}`;

      link.download = name;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getCurrentDate() {
      const date = new Date();

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    reader(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });
    },
    setImage(id) {
      this.selectImageFromMediaLibrary = false;
      this.$emit("input", id);
    },
    formatDate(timestamp) {
      let dateObject = new Date();
      dateObject.setTime(timestamp);

      let date =
        dateObject.getDate() +
        "-" +
        (dateObject.getMonth() + 1) +
        "-" +
        dateObject.getFullYear();
      let time =
        dateObject.getHours() +
        ":" +
        dateObject.getMinutes() +
        ":" +
        dateObject.getSeconds();

      return date + " " + time;
    },
  },
};
</script>

<style>
.spin {
  -webkit-animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
