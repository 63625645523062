<template>
  <div class="relative" @click="showSelectButton = true">
    <div class="h-32 overflow-hidden object-cover">
      <BaseImageViewer :value="id" />
    </div>
    <div class="py-3">
      <BaseButton color="green" @click="$emit('selected', id)">Select</BaseButton>
    </div>
  </div>
</template>
<script>

import BaseImageViewer from '~/components/base/BaseImageViewer'
import BaseButton from '~/components/base/BaseButton'

export default {
  components: { BaseButton, BaseImageViewer },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      showSelectButton: false
    }
  }
}
</script>
