<script>
export default {
  data () {
    return {
      isOnline: navigator.onLine,
      onlineListener: window.addEventListener('online', this.update(true)),
      offlineListener: window.addEventListener('offline', this.update(false))
    }
  },
  computed: {
    status () {
      return this.isOnline ? 'online' : 'offline'
    }
  },
  destroyed () {
    window.removeEventListener('online', this.onlineListener)
    window.removeEventListener('offline', this.offlineListener)
  },
  methods: {
    update (value) {
      return (e) => {
        this.isOnline = value
      }
    }
  },
  render (h) {
    return this.$scopedSlots.default({
      isOnline: this.isOnline,
      status: this.status
    })
  }
}
</script>
