export default [
  {
    id: 1,
    name: 'Inspection'
  },
  {
    id: 2,
    name: 'Inspection & Repair'
  },
  {
    id: 3,
    name: 'Repair'
  }
]
