export default [
  {
    id: null,
    name: 'No Damage'
  },
  {
    id: 1,
    name: 'Cracks'
  },
  {
    id: 2,
    name: 'Coat damage'
  },
  {
    id: 3,
    name: 'Cosmetic'
  },
  {
    id: 4,
    name: 'Delamination'
  },
  {
    id: 5,
    name: 'Defective repair'
  },
  {
    id: 6,
    name: 'STE'
  },
  {
    id: 7,
    name: 'Vortex'
  },
  {
    id: 8,
    name: 'Gurney flaps'
  },
  {
    id: 9,
    name: 'Lightning damage'
  },
  {
    id: 10,
    name: 'Erosion'
  },
  {
    id: 11,
    name: 'Manufacture defect'
  },
  {
    id: 12,
    name: 'Mechanical damage'
  },
  {
    id: 13,
    name: 'Sensor'
  },
  {
    id: 14,
    name: 'Other'
  },
  {
    id: 15,
    name: 'Fiber glass damage'
  },
  {
    id: 16,
    name: 'Dirt'
  },
  {
    id: 17,
    name: 'Oil/grease'
  }
]

