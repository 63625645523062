var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-between w-full"},[_c('h3',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('BaseButton',{attrs:{"color":"red"},on:{"click":_vm.clear}},[_vm._v("\n            Clear\n        ")])],1),_vm._v(" "),_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageSize}},[_c('v-layer',{ref:"layer",on:{"click":_vm.canvasTappedEvent,"tap":_vm.canvasTappedEvent}},[_c('v-image',{attrs:{"config":{ image: _vm.imageToRender, y: _vm.padding, x: _vm.padding }}}),_vm._v(" "),_c('v-image',{ref:"circle",attrs:{"image":_vm.crossImageForSelection,"config":{
                    width: _vm.crossSize,
                    height: _vm.crossSize,
                    draggable: true,
                    x: this.x,
                    y: _vm.value.pos_y
                },"dragBoundFunc":_vm.dragBoundFunc},on:{"dragend":_vm.circleDraggedEvent}}),_vm._v(" "),_vm._l((_vm.otherDamages),function(otherDamage){return _c('v-image',{key:otherDamage.id,attrs:{"image":_vm.crossImageForOtherSelections,"config":{
                    width: _vm.crossSize,
                    height: _vm.crossSize,
                    draggable: false,
                    x: _vm.calculateX(otherDamage.radius),
                    y: otherDamage[_vm.type].pos_y
                }}})})],2)],1),_vm._v(" "),_c('BladeSizeScale',{staticClass:" bottom-24 bottom-45",attrs:{"width":_vm.stageSize.width,"padding":_vm.padding}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }