<template>
  <component :is="component(item.component)" :item="item"></component>
</template>

<script>
// Helpers
import { sync } from 'vuex-pathify'
import component from '@/helpers/component.js'

export default {
  props: {
    valid: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    menu: sync('menu')
  },
  watch: {
    valid: {
      handler (value) {
        this.menu.items = this.menu.items.map((item) => {
          if (this.item.title === item.title) {
            return {
              ...item,
              validated: value
            }
          }
          return item
        })
      },
      immediate: true
    }
  },
  methods: {
    component
  }
}
</script>
