import report from '@/database/models/report.js'
import uuid from '@/helpers/generateUuid.js'

export default async (_report, _blade) => {
  _report = saveBlade(_report, _blade)

  const row = await report.get(_report.id)
  if (row === null) {
    await report.store(_report.id, _report)
  } else {
    await report.update(_report.id, _report)
  }
}

const saveBlade = (_report, _blade) => {
  if (_blade === undefined) {
    return _report
  }
  if (_blade.id === undefined) {
    // Push to blade array if new
    _blade.id = uuid()
    _report.blades = [..._report.blades, _blade]
  } else {
    // Replace blade in array if edit
    _report.blades = _report.blades.map(blade => {
      if (blade.id === _blade.id) {
        return _blade
      }
      return blade
    })
  }

  return _report
}
