import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";
import Vue from 'vue';

if (process.env.NODE_ENV === 'production') {
    flare.addContext('version', process.env.VERSION);
    flare.light('NY6n0SEvcgEqwgefeMtG5vyS9uco6VG8');
}

window.flare = flare;

Vue.use(flareVue);
