<template>
  <div class="mt-12">
    <ul v-if="drafts.length || loading" class="w-full mb-32">
      <DraftsListItem v-for="(draft, i) in drafts" :key="i" :draft="draft" @update="getDrafts"></DraftsListItem>
    </ul>
    <div v-else class="flex items-center justify-center w-full py-6 border rounded">
      No drafts to import
    </div>
  </div>
</template>

<script>
// Helpers
import { sync } from "vuex-pathify";

export default {
  data () {
    return {
      drafts: [],
      loading: true
    }
  },
  mounted () {
    this.getDrafts()
  },
  computed: {
    user: sync("auth/user"),
  },
  methods: {
    async getDrafts () {
      this.$axios.get('/api/drafts').then(res => {
        this.drafts = res.data
        this.loading = false
      }).catch((err) => {
        if (err.response.status === 401) {
          this.user = undefined;
          this.$toasted.show("Session Time-out", {
            duration: 5000,
            position: "bottom-right",
            type: "error",
          });
          return;
        }
        console.log(error.response)
      })
    }
  }
}
</script>
